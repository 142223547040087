import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Stepper,
	Step,
	StepButton,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	TextField,
	IconButton,
	LinearProgress,
	MenuItem,
} from '@material-ui/core';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import * as colors from '../colors';
import { STORE, getStateVariables } from '../redux/selectors';
import { updateProperties, updateExternalControlPanels, updateUserInfo } from '../redux/actionCreators';
import { createDeepCopy } from '../utility-functions';
import Select from '../components/SelectWrapper';
import SelectionTable from './SelectionTable';
import EditPopup from './EditPopup';
import TooltipWrapper from './TooltipWrapper';
import DucSelection from './DucSelection';
import DucUploadButton from './DucUploadButton';
import ImportedRegisterList from './ImportedRegisterList';
import i18n from '../i18n';
import SensorSelection from './SensorSelection';
import { read } from 'xlsx';

const STEPS = {
	property: { id: 'property', label: i18n.t('generic.property') },
	duc: { id: 'duc', label: i18n.t('generic.bms') },
	import: { id: 'import', label: i18n.t('registrationWizard.curve') },
	sensors: { id: 'sensors', label: i18n.t('constants.sensors') },
	supplytemp: { id: 'supplytemp', label: i18n.t('registrationWizard.utilitySensors') },
	sensorGroups: { id: 'sensorGroups', label: i18n.t('registrationWizard.sensorGroups') },
	summary: { id: 'summary', label: i18n.t('constants.overview') },
};

const RADIO_COMPONENT = <div></div>;
const INPUT_TYPE = Object.freeze({ Int: 1, Float: 2 });


const GET_SENSORS = gql`
	query ($filter: SensorFilter) {
		getSensors(filter: $filter) {
			sensorid
			name
		}
	}
`;

const ADD_AI_CONTROL = gql`
mutation ($locationid: ID, $communicationtype: String, $ducid: ID, $name: String, $sensortype: String, $system: ID, $minimum: Float, $maximum: Float, $sensorid: String) {
    addAiControl(locationid: $locationid, communicationtype: $communicationtype, ducid: $ducid, name: $name, sensortype: $sensortype, system: $system, minimum: $minimum, maximum: $maximum, sensorid: $sensorid) {
      index
      locationid
	  ducid
      communicationtype
	  name
	  sensortype
	  system
	  minimum
	  maximum
	  sensorid
    }
  }
`;

const GET_IMPORTEDREGISTERS = gql`
    query ($filter: ImportedRegisterFilter!) {
        getImportedRegisters(filter: $filter) {
            externalcontrolpanelid
            objectname
            groupname
            parametername
            address
            canread
            canwrite
            read
            write
            automation
        }
    }
`;

const SET_PROPERTIES = gql`
	mutation ($locationids: [ID]!, $AI: Boolean,) {
		setSensorLocations(
			locationids: $locationids
			AI: $AI
		) {
			locationid
			AI
		}
	}
`;


function RegistrationWizardAutomation(props) {
	const [curStepI, setCurStepI] = useState(0);
	const [completedStepIds, setCompletedStepIds] = useState([]);

	let STEPSArray = Object.values(STEPS);
	//if (props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id)
	STEPSArray = STEPSArray.filter(t => !(t.id == 'sensorGroups' || t.id == 'property'));

	const { t } = useTranslation();

	const currentStep = STEPSArray[curStepI];
	const onLastStep = curStepI === STEPSArray.length - 1;

	const [selectedCurPropertyId, setSelectedCurPropertyId] = useState(props.selectedPropertyId);


	const [selectedECP, setSelectedECP] = useState();

	const [selectedReadRegs, setSelectedReadRegs] = useState([]);
	const [selectedWriteRegs, setSelectedWriteRegs] = useState([]);

	const [formattedSheet, setFormattedSheet] = useState();
	const [importedRegs, setImportedRegs] = useState([]);

	const [unregSensors, setUnregSensors] = useState([]);
	const [selectedSens, setSelectedSens] = useState([]);
	const [selectedSupplySens, setSelectedSupplySens] = useState([]);
	const [selectedOutSens, setSelectedOutSens] = useState([]);
	const [selectedElecSens, setSelectedElecSens] = useState([]);

	const [newGroups, setNewGroups] = useState([]);

	const [minTemp, setMinTemp] = useState();
	const [maxTemp, setMaxTemp] = useState();


	useQuery(GET_SENSORS, {
		variables: { filter: { locationids: props.selectedPropertyId } },
		//skip: props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id || unregSensors.length,
		onCompleted: ({ getSensors }) => setUnregSensors(createDeepCopy(getSensors)),
	});

	useQuery(GET_IMPORTEDREGISTERS, {
		variables: { filter: { externalcontrolpanelids: props.externalControlPanels.map(ecp => ecp.externalcontrolpanelid) } },
		onCompleted: ({ getImportedRegisters }) => setImportedRegs(getImportedRegisters),
		fetchPolicy: 'no-cache',
	});

	const [addAiControl] = useMutation(ADD_AI_CONTROL, {
		onCompleted: () => console.log('AI control added.'),
		onError: (error) => console.error('Failed to add aicontrol.', error),
	});


	const [setProperties] = useMutation(SET_PROPERTIES, {
		onCompleted: ({ setSensorLocations }) => props.updateProperties(setSensorLocations),
	});


	const cities = []; // [{value: Number, label: String}, ...]
	for (const prop of props.properties)
		if (!cities.some(city => city.value === prop.city)) cities.push({ value: prop.city, label: prop.city });
	cities.sort((a, b) => (a.label < b.label ? -1 : 1));

	function ExtractCityStreet() {
		let property = { city: t('registrationWizard.noCitySelected'), street: t('registrationWizard.noAddressSelected') };
		if (selectedCurPropertyId) {
			property = props.properties.find(pro => pro.locationid === selectedCurPropertyId);
		}
		return `${property?.city}: ${property?.street}`;
	}

	const handleMinTempChange = (event) => {
		setMinTemp(parseFloat(event.target.value));
	};

	const handleMaxTempChange = (event) => {
		setMaxTemp(parseFloat(event.target.value));
	};

	function ValidateStep() {
		let disableButton = false;
		switch (curStepI) {
			case 0:
				if
					(!selectedCurPropertyId)
					disableButton = true;
				break;
			case 1:
				if (!selectedECP) disableButton = true;
				break;
			default:
				break;
		}
		return disableButton;
	}

	async function SaveChanges() {
		if (selectedReadRegs.length > 0 || selectedWriteRegs.length > 0) {
			selectedReadRegs
				.filter(e => !selectedWriteRegs.some(f => f === e))
				.forEach(sen => {
					let readFormated = importedRegs.find(readReg => readReg.address === sen);
					addAiControl({
						variables: {
							locationid: props.selectedPropertyId,
							communicationtype: 'modbus',
							ducid: selectedECP,
							name: readFormated ? readFormated.groupname : null,
							sensortype: readFormated ? readFormated.parametername : null,
							//system: 1,
						},
					});
				});
		}



		if (selectedSens) {
			selectedSens.forEach(sen => {
				addAiControl({
					variables: {
						locationid: props.selectedPropertyId,
						communicationtype: 'sensor',
						ducid: selectedECP,
						name: sen.name,
						sensortype: 'indoortemp',
						sensorid: sen.sensorid,
					},
				});
			});
		}

		if (selectedSupplySens && selectedSupplySens.value) {
			addAiControl({
				variables: {
					locationid: props.selectedPropertyId,
					communicationtype: 'sensor',
					ducid: selectedECP,
					name: selectedSupplySens.label,
					sensortype: 'supplytemp',
					sensorid: selectedSupplySens.value,
				},
			});
		}

		if (selectedOutSens && selectedOutSens.value) {
			addAiControl({
				variables: {
					locationid: props.selectedPropertyId,
					communicationtype: 'sensor',
					ducid: selectedECP,
					name: selectedOutSens.label,
					sensortype: 'outdoortemp',
					sensorid: selectedOutSens.value,
				},
			});
		}

		if (selectedElecSens && selectedElecSens.value) {
			addAiControl({
				variables: {
					locationid: props.selectedPropertyId,
					communicationtype: 'sensor',
					ducid: selectedECP,
					name: selectedElecSens.label,
					sensortype: 'electricity',
					sensorid: selectedElecSens.value,
				},
			});
		}

		addAiControl({
			variables: {
				locationid: props.selectedPropertyId,
				communicationtype: 'sensor',
				ducid: selectedECP,
				name: "minimumtemp",
				sensortype: 'mintemp',
				sensorid: '0',
				minimum: minTemp || 20.5,
				maximum: maxTemp || 23,
			},
		});
		addAiControl({
			variables: {
				locationid: props.selectedPropertyId,
				communicationtype: 'sensor',
				ducid: selectedECP,
				name: "maximumtemp",
				sensortype: 'maxtemp',
				sensorid: '0',
				minimum: minTemp || 20.5,
				maximum: maxTemp || 23,
			},
		});

		setProperties({
			variables: {
				locationids: [props.selectedPropertyId],
				AI: true,

			},
		});

		props.closeWizard();
		Swal.fire(t('userAdmin.success'), t('registrationWizard.aiControl'), 'success');
	}
	function renderDuc() {
		return (
			<>
				{/* <RadioGroup value={ducRadioSel} onChange={e => setDucRadioSel(Number(e.target.value))} style={{ marginLeft: '0.2rem' }}> */}
				{/* {propRadioSel === 0 ? ( */}
				<>
					<FormControlLabel value={0} control={RADIO_COMPONENT} label={t('registrationWizard.selectDuc')} style={{ marginLeft: '29px' }} />
					<div
						style={{
							width: '20rem',
							margin: '.3rem 0 1.5rem 1.7rem',
							...({}),
						}}
					>
						<DucSelection citystreet={ExtractCityStreet()} setSelectedECP={setSelectedECP}></DucSelection>
					</div>
					{/* <FormControlLabel value={1} control={RADIO_COMPONENT} label='Ny DUC' />{' '} */}
				</>
				{/* ) : null} */}
			</>
		);
	}
	function renderImport() {
		return (
			<>
				<ImportedRegisterList
					showTitle={false}
					selectedReadRegs={selectedReadRegs}
					setSelectedReadRegs={setSelectedReadRegs}
					selectedWriteRegs={selectedWriteRegs}
					setSelectedWriteRegs={setSelectedWriteRegs}
					disableButton={true}
					type='add'
					ecpId={selectedECP}
					data={importedRegs.filter(reg => reg.externalcontrolpanelid === selectedECP)}
					canSave={selectedECP}
					onSave={() => {
						//setFileName();
						setFormattedSheet();

					}}
					style={{ marginTop: '1.2rem' }}
				/>
			</>
		);
	}
	function renderSensor() {
		return (
			<SelectionTable
				localization={{
					title: t('registrationWizard.indoorTempSensor'),
					nRowsSelected: t('registrationWizard.numberOfSelectedSensors') + '{0}',
				}} //TODO: Find a way to do this better
				data={[...props.sensors.filter(f => f.locationid === props.selectedPropertyId)]}
				dataId='sensorid'
				onSelectionChange={sens => setSelectedSens(sens)}
				columns={[
					{ title: t('generic.id'), field: 'name', maxLength: 200 },
					{ title: t('generic.unit'), field: 'unit' }
				  ]}
				pageSizeOptions={[]}
				tableProps={{ maxColumnLength: 28 }}
			/>
		);
	}

	function renderSupplyTemp() {
		return (
			<>
				<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0', marginBottom: '2rem' }}>
					{t('registrationWizard.supplyTemp')}

					<SensorSelection
						locationid={props.selectedPropertyId} // Pass setSelectedSupplySens as a prop
						setSelectedSensor={setSelectedSupplySens}
						onSelectionChange={sens => setSelectedSupplySens(sens)}
					/>
				</div>
				<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0', marginBottom: '2rem' }}>
					{t('registrationWizard.outdoorTemp')}
					<SensorSelection
						locationid={props.selectedPropertyId} // Pass setSelectedSupplySens as a prop
						setSelectedSensor={setSelectedOutSens}
						onSelectionChange={sens => setSelectedOutSens(sens)}
					/>
				</div>
				<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0', marginBottom: '2rem' }}>
					{t('registrationWizard.electricity')}
					<SensorSelection
						locationid={props.selectedPropertyId} // Pass setSelectedSupplySens as a prop
						setSelectedSensor={setSelectedElecSens}
						onSelectionChange={sens => setSelectedElecSens(sens)}
					/>
				</div>

				<div style={{ display: 'flex', flexWrap: 'wrap', fontWeight: '800', margin: '1rem 0 .2rem 0', marginBottom: '2rem', gap: '20px' }}>
					{t('registrationWizard.thresholds')}
					<div>
						<TextField
							id="outlined-controlled-minimum"
							label={t('generic.minimum')}
							type="number"
							value={minTemp}
							onChange={handleMinTempChange}
							variant='outlined'
							InputLabelProps={{ style: { color: 'black' } }}
						/>
					</div>
					<div>
						<TextField
							id="outlined-controlled-maximum"
							label={t('generic.maximum')}
							type="number"
							value={maxTemp}
							onChange={handleMaxTempChange}
							variant='outlined'
							InputLabelProps={{ style: { color: 'black' } }}
						/>
					</div>
				</div>
			</>
		);
	}

	function renderSummary() {
		return (
			<>
				<div id='Fastighetbefintligny'>
					<div style={{ fontWeight: '800', marginBottom: '.2rem' }}>
						{t('generic.property')} {/* ({propRadioSel === 0 ? t('registrationWizard.existing') : t('registrationWizard.new')}) */}
					</div>
					<div style={{ fontWeight: '400', display: 'flex' }}>
						<div>
							{[
								t('generic.address'),
								t('generic.city'),
								t('generic.label'),
								t('registrationWizard.squareMetres'),
								t('registrationWizard.longitude'),
								t('registrationWizard.latitude'),
							].map(v => (
								<div>{v}</div>
							))}
						</div>
						<div style={{ marginLeft: '1rem' }}>
							{[
								props.properties.find(pro => pro.locationid === selectedCurPropertyId) || {}

							]
								.map(pro => [pro.street, pro.city, pro.cadastral, pro.area, pro.longitude, pro.latitude])
								.flat()
								.map(v => (
									<div>{v || ''}</div>
								))}
						</div>
					</div>
				</div>

				{selectedSens && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.freestandingSensors')}</div>
				)}

				{selectedSens
					.filter(
						sen => !newGroups.flatMap(newgroup => newgroup.sensors.map(sensor => sensor.sensorid)).some(t => t == sen.sensorid)
					)
					.map(sen => (
						<div style={{ fontWeight: '400' }}>{sen.name || sen.sensorref || '-'}</div>
					))}

				{selectedSupplySens && selectedSupplySens.value && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>
						{t('registrationWizard.supplyTemp')}
					</div>
				)}

				{selectedSupplySens && (
					<div style={{ fontWeight: '400' }}>
						{selectedSupplySens.value}  {selectedSupplySens.label}
					</div>
				)}
				{selectedOutSens && selectedOutSens.value && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>
						{t('registrationWizard.outdoorTemp')}
					</div>
				)}

				{selectedOutSens && (
					<div style={{ fontWeight: '400' }}>
						{selectedOutSens.value}  {selectedOutSens.label}
					</div>
				)}
				{selectedElecSens && selectedElecSens.value && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>
						{t('registrationWizard.electricity')}
					</div>
				)}

				{selectedElecSens && (
					<div style={{ fontWeight: '400' }}>
						{selectedElecSens.value}  {selectedElecSens.label}
					</div>
				)}

				<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.thresholds')}</div>

				<div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: 'auto', gap: '20px' }}>
					<div>
						{t('generic.maximum')}: {minTemp}
					</div>
					<div >
						{t('generic.maximum')}: {maxTemp}
					</div>
				</div>


				{newGroups.length > 0 && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.sensorGroups')}</div>
				)}
				{newGroups.map(newGroup => (
					<>
						<div style={{ fontWeight: '400' }}>{newGroup.name}</div>
						{newGroup.sensors.map(sensor => (
							<div style={{ marginLeft: '1rem', fontWeight: '400' }}>{`	${sensor.sensorref}`}</div>
						))}
					</>
				))}
				{(selectedReadRegs.length > 0 || selectedWriteRegs.length > 0) && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.modbus')}</div>
				)}

				<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, max-content)', columnGap: '1rem', fontWeight: '400' }}>
					{selectedReadRegs
						.filter(e => selectedWriteRegs.some(f => f === e))
						.map(sen => {
							let readFormated = formattedSheet.find(readReg => readReg.address === sen);
							return (
								<>
									<div>{readFormated.objectname}</div>
									<div>{readFormated.parametername}</div>
									<div>{t('registrationWizard.readWrite')}</div>
								</>
							);
						})}

					{selectedReadRegs
						.filter(e => !selectedWriteRegs.some(f => f === e))
						.map(sen => {
							let readFormated = importedRegs.find(readReg => readReg.address === sen);
							return (
								<>
									<div>{readFormated.objectname}</div>
									<div>{readFormated.parametername}</div>
									<div>{t('registrationWizard.read')}</div>
								</>
							);
						})}
					{selectedWriteRegs
						.filter(e => !selectedReadRegs.some(f => f === e))
						.map(sen => {
							let writeFormated = formattedSheet.find(writeReg => writeReg.address === sen);
							return (
								<>
									<div>{writeFormated.objectname}</div>
									<div>{writeFormated.parametername}</div>
									<div>{t('registrationWizard.write')}</div>
								</>
							);
						})}
				</div>
			</>
		);
	}

	function renderWindowSwitch(stepId) {
		let functioncall = () => <></>;
		switch (stepId) {
			case STEPS.duc.id:
				functioncall = renderDuc;
				break;
			case STEPS.import.id:
				functioncall = renderImport;
				break;
			case STEPS.sensors.id:
				functioncall = renderSensor;
				break;
			case STEPS.supplytemp.id:
				functioncall = renderSupplyTemp;
				break;
			case STEPS.summary.id:
				functioncall = renderSummary;
				break;

			default:
				functioncall = () => <></>;
				break;
		}

		return functioncall();
	}
	return (
		<>
			<Stepper nonLinear activeStep={curStepI} style={{ margin: '0 -1rem .4rem' }}>
				{STEPSArray.map((step, i) => (
					<Step key={step.id} completed={completedStepIds.includes(step.id)}>
						<StepButton
							style={{ [curStepI < i && 'pointerEvents']: 'none', color:colors.primary }}
							onClick={() => setCurStepI(i)}
						>
							{step.label}
						</StepButton>
					</Step>
				))}
			</Stepper>

			<div style={{ margin: '0 1rem' }}>{renderWindowSwitch(currentStep.id)}</div>

			<div style={{ display: 'flex', margin: '1.5rem .9rem 1rem auto' }}>
				<Button
					color='primary'
					variant='outlined'
					disabled={curStepI === 0}
					onClick={() => setCurStepI(curStepI - 1)}
					style={{ margin: '0 1rem 0 0' }}
				>
					{t('generic.back')}
				</Button>
				<Button
					color='primary'
					disabled={ValidateStep()}
					variant='outlined'
					onClick={() => (!onLastStep ? setCurStepI(curStepI + 1) : SaveChanges())}
				>
					{!onLastStep ? t('registrationWizard.continue') : t('generic.save')}
				</Button>
			</div>
		</>
	);
}

export default connect(getStateVariables(STORE.callbacks, STORE.properties, STORE.sensors, STORE.userInfo, STORE.externalControlPanels, STORE.currentProperty), {
	updateExternalControlPanels,
	updateProperties,
	updateUserInfo,
})(RegistrationWizardAutomation);
